import React, { useMemo } from "react"
import { graphql } from "gatsby"
import {
    Layout,
    useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

import BlogPageSection from "../components/BlogPageSection"
import BlogFooterComponent from "../components/BlogFooterComponent"

export default function Blog({ data, pageContext }) {
    const translation = useTranslate()

    const customSeo = useMemo(() => {
        const title = translation["blog.page.title"].text.replace(
            "{page}",
            pageContext.pageNr
        )
        return {
            title,
            description:
                pageContext.pageNr < 2
                    ? data.blogPage.seo?.description
                    : pageContext.pageNr === 2
                    ? translation["blog.page.seo.second.description"].text
                    : pageContext.pageNr === 3
                    ? translation["blog.page.seo.third.description"].text
                    : pageContext.pageNr >= 4
                    ? data.posts.edges
                          .map((p) => p.node.title)
                          .join(", ")
                          .slice(0, 150) + "..."
                    : null,
            image: data.blogPage.seo?.image?.file
                ? data.blogPage.seo.image.file
                : null,
            removeSuffix: false,
            alternateLanguages: [],
        }
    }, [translation, data.blogPage, pageContext.pageNr, data.posts])

    return (
        <Layout
            navbarFixed={true}
            navbarTransparent={true}
            isDark={false}
            customSeo={customSeo}
        >
            <BlogPageSection
                title={customSeo.title}
                posts={data.posts.edges.map((p) => p.node)}
                nextPage={
                    pageContext.isLastPage ? null : pageContext.pageNr + 1
                }
                previousPage={pageContext.pageNr - 1}
            />
            <BlogFooterComponent />
        </Layout>
    )
}

export const query = graphql`
    query Blog($skip: Int, $limit: Int) {
        posts: allWpPost(
            filter: { status: { eq: "publish" } }
            sort: { order: DESC, fields: date }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    ...BasicWordpressPost
                }
            }
        }
        blogPage: datoCmsPage(isBlogPage: { eq: true }) {
            seo {
                description
                title
                image {
                    file {
                        childImageSharp {
                            resize(width: 1200, height: 630) {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`
